import DEFAULT_CANVAS_PROPERTIES from '../data/defaultCanvasProperties';
import getNewCanvasWidth from '../helpers/getNewCanvasWidth';

const resizeCanvas = (fabricRef, isMenuExpanded) => {
  if (fabricRef?.current) {
    const menuState = isMenuExpanded ? 'expandedMenu' : 'reducedMenu';

    const scale =
      DEFAULT_CANVAS_PROPERTIES.width /
      getNewCanvasWidth(menuState);

    const zoom = DEFAULT_CANVAS_PROPERTIES.zoom / scale;

    fabricRef.current.setDimensions({
      width: getNewCanvasWidth(menuState),
      height:
        getNewCanvasWidth(menuState) /
        DEFAULT_CANVAS_PROPERTIES.widthHeightRatio,
    });

    fabricRef.current.setZoom(zoom);
  }
};

export default resizeCanvas;
