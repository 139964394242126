import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import { useQueryLocalApp } from 'gql/queries/local';
import useSiteContext from 'hooks/useSiteContext';
import imageWooflashLogo from 'images/logos/logo.svg';
import Button from 'uikit/Button';

import styles from './SubPage.module.scss';

const cx = classNames.bind(styles);

const SubPage = ({
  children,
  className = null,
  header = null,
  headerSize = 'large',
  isFullScreen = false,
  isOnboarding = false,
  isOpen = false,
  isSidePanel = false,
  onExit = null,
  subtitle = null,
  title = null,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'uikit/SubPage',
  });

  const { isInTeams } = useSiteContext();

  const {
    data: {
      app: { isMenuExpanded },
    },
  } = useQueryLocalApp();

  const nodeRef = useRef(null);

  useEffect(() => {
    function handleKey(event) {
      if (event.key === 'Escape') {
        onExit?.(event);
      }
    }
    window.addEventListener('keydown', handleKey);
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [onExit]);

  function onOverlayClickHandler(clickEvent) {
    clickEvent?.stopPropagation();

    return onExit?.(clickEvent);
  }

  return createPortal(
    <CSSTransition
      classNames={{
        enter: cx('enter'),
        enterActive: cx('enter-active'),
        exit: cx('exit'),
        exitActive: cx('exit-active'),
      }}
      in={isOpen}
      mountOnEnter
      nodeRef={nodeRef}
      timeout={500}
      unmountOnExit
    >
      <div
        className={cx(className, 'overlay', {
          'collapsed-menu-overlay': !isMenuExpanded,
          'full-screen-overlay': isFullScreen || isInTeams,
          'side-panel-overlay': isSidePanel,
        })}
        onClick={onOverlayClickHandler}
        ref={nodeRef}
      >
        <div
          className={cx('modal', {
            'full-screen-modal': isFullScreen,
            'collapsed-menu-modal': !isMenuExpanded,
            'side-panel-modal': isSidePanel,
            'large-side-panel-modal': isSidePanel && !isMenuExpanded,
          })}
          onClick={(event) => event.stopPropagation()}
        >
          {!isSidePanel && !isOnboarding && (
            <div className={cx('header', `header-size-${headerSize}`)}>
              {isFullScreen ? (
                <>
                  <div className={cx('logo')}>
                    <img
                      alt=""
                      aria-hidden="true"
                      className={cx('logo-image')}
                      src={imageWooflashLogo}
                    />
                    <span className={cx('logo-text')}>wooflash</span>
                  </div>
                  <Button
                    icon="times"
                    intent="white"
                    onClick={onExit}
                    size="large"
                  >
                    {t('back')}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    intent="white"
                    icon="arrow-left"
                    onClick={onExit}
                    size={headerSize}
                    tooltip={t('back')}
                  />
                  <div>
                    {!title && !subtitle && header}
                    {title && <h3 className={cx('title')}>{title}</h3>}
                    {subtitle && <h4 className={cx('subtitle')}>{subtitle}</h4>}
                  </div>
                </>
              )}
            </div>
          )}
          {children}
        </div>
      </div>
    </CSSTransition>,
    document.body,
  );
};

SubPage.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  header: PropTypes.node,
  headerSize: PropTypes.string,
  isFullScreen: PropTypes.bool,
  isOnboarding: PropTypes.bool,
  isOpen: PropTypes.bool,
  isSidePanel: PropTypes.bool,
  onExit: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default SubPage;
