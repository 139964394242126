import classNames from 'classnames/bind';
import { fabric } from 'fabric';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Tex2SVG from 'react-hook-mathjax';
import { useTranslation } from 'react-i18next';
import storeJS from 'store';

import Button from 'uikit/Button';

import { saveHistory } from '../config/history';
import DEFAULT_CANVAS_PROPERTIES from '../data/defaultCanvasProperties';
import THEMES from '../data/themes';
import styles from './TextToolbar.module.scss';

const cx = classNames.bind(styles);

const TextToolbar = ({
  className = null,
  fabricRef = null,
  isDisplayedInColumn,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Shared/SlideBuilder/TextToolbar',
  });

  const [textFormula, setTextFormula] = useState();
  const [formula, setFormula] = useState();

  function addFormulaToCanvas(object) {
    const groupedFormula = fabric.util.groupSVGElements(object, {
      ...DEFAULT_CANVAS_PROPERTIES.SVGProps,
    });

    // set color to the formula
    // eslint-disable-next-line no-underscore-dangle
    groupedFormula._objects.forEach((character) =>
      character.set({
        fill: '#1d254f',
      }),
    );

    // Add formula to the canvas
    fabricRef.current.add(groupedFormula).renderAll();

    setTextFormula('');
  }

  function getTextProps(type) {
    const theme = THEMES[storeJS.get('slide')?.themeIndex];

    if (type === 'title') {
      return {
        ...DEFAULT_CANVAS_PROPERTIES.titleProps,
        ...theme?.titleProps,
        // if we don't add it,
        // design changes on one title element
        // are applied to every titles in the canvas
        styles: [],
      };
    }
    if (type === 'subtitle') {
      return {
        ...DEFAULT_CANVAS_PROPERTIES.subtitleProps,
        ...theme?.subtitleProps,
        // if we don't add it,
        // design changes on one subtitle element
        // are applied to every subtitles in the canvas
        styles: [],
      };
    }
    return {
      ...DEFAULT_CANVAS_PROPERTIES.textProps,
      ...theme?.textProps,
      // if we don't add it,
      // design changes on one text element
      // are applied to every texts in the canvas
      styles: [],
    };
  }

  function handleFormulaAdd() {
    saveHistory(fabricRef);
    fabric.loadSVGFromString(formula, (object) => addFormulaToCanvas(object));
  }

  function handleFormulaChange(event) {
    setTextFormula(event.target.value);
  }

  function handleFormulaSave(html) {
    setFormula(html.outerHTML);
  }

  function handleTextAdd(type) {
    saveHistory(fabricRef);
    const text = new fabric.Textbox(t(`${type}`), getTextProps(type));
    fabricRef.current.add(text).setActiveObject(text).renderAll();
  }

  return (
    <div>
      <div
        className={cx('container', className, { column: isDisplayedInColumn })}
      >
        <p className={cx('title')}>{t('text-title')}</p>
        {['title', 'subtitle', 'text'].map((textElement) => (
          <Button
            className={cx('action')}
            key={textElement}
            onClick={() => handleTextAdd(textElement)}
          >
            {t(`${textElement}-add`)}
          </Button>
        ))}
      </div>
      <div className={cx('container', className)}>
        <p className={cx('title')}>{t('formula-title')}</p>
        <div className={cx('formula-input-container')}>
          <input
            className={cx('input', { margin: !textFormula })}
            onChange={handleFormulaChange}
            type="text"
            placeholder={t('formula-placeholder')}
            value={textFormula}
          />
          <Button
            className={cx('formula-button')}
            intent={textFormula ? 'primary' : 'default'}
            onClick={handleFormulaAdd}
          >
            Ok
          </Button>
        </div>
        <div className={cx('formula-preview', { visible: !!textFormula })}>
          <Tex2SVG
            latex={textFormula}
            onSuccess={(html) => handleFormulaSave(html)}
          />
        </div>
      </div>
    </div>
  );
};

TextToolbar.propTypes = {
  className: PropTypes.string,
  fabricRef: PropTypes.object,
  isDisplayedInColumn: PropTypes.bool.isRequired,
};

export default TextToolbar;
