import PROPERTIES_TO_SAVE from '../data/propertiesToSave';

const history = [];
const HISTORY_LIMIT = 20;

function getJSONToSave(fabricRef, stepToSave) {
  const lastHistoryStep = history[history.length - 1];
  const canvasContent = JSON.stringify(
    fabricRef.current.toJSON(PROPERTIES_TO_SAVE),
  );
  if (stepToSave && stepToSave !== lastHistoryStep) {
    return stepToSave;
  }
  if (canvasContent !== lastHistoryStep) {
    return canvasContent;
  }
  return null;
}

export const saveHistory = (fabricRef, stepToSave) => {
  const jsonToSave = getJSONToSave(fabricRef, stepToSave);
  if (jsonToSave) {
    history.push(jsonToSave);
    if (history.length > HISTORY_LIMIT) {
      history.shift();
    }
  }
};

export const undo = (fabricRef) => {
  const lastStepContent = history[history.length - 1];
  if (lastStepContent) {
    fabricRef.current.loadFromJSON(lastStepContent);
    history.pop();
  }
};

export const clearHistory = () => {
  history.length = 0;
};
