import classNames from 'classnames/bind';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import storeJS from 'store';

import Tabs from 'uikit/Tabs';

import { saveHistory } from '../config/history';
import TEMPLATES from '../data/templates';
import THEMES from '../data/themes';
import changeTemplate from '../helpers/changeTemplate';
import changeTheme from '../helpers/changeTheme';
import styles from './DesignToolbar.module.scss';

const cx = classNames.bind(styles);

const DesignToolbar = ({
  className = null,
  fabricRef = null,
  isDisplayedInColumn,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Shared/SlideBuilder/DesignToolbar',
  });
  const { t: tTemplate } = useTranslation();

  const [{ value: currentSlideContent }] = useField('slideContent');

  function handleThemeChange(index) {
    saveHistory(fabricRef);
    changeTheme(fabricRef, index);
    fabricRef.current.renderAll();
    storeJS.set('slide', {
      ...storeJS.get('slide'),
      themeIndex: index,
      backgroundColor: undefined,
    });
  }

  function handleTemplateChange(templateIndex) {
    saveHistory(fabricRef);
    changeTemplate({
      fabricRef,
      t: tTemplate,
      templateIndex,
      currentSlide: JSON.parse(currentSlideContent).objects,
    });
    fabricRef.current.renderAll();
  }

  const selectedThemeIndex = storeJS.get('slide')?.themeIndex;

  return (
    <div
      className={cx('container', className, { column: isDisplayedInColumn })}
    >
      <Tabs className={cx('tabs')}>
        <Tabs.Tab label={t('template-title')}>
          <div className={cx('templates', { column: isDisplayedInColumn })}>
            {TEMPLATES.map((template, index) => (
              <button
                aria-label={t('template', { index })}
                className={cx('button-container')}
                // eslint-disable-next-line react/no-array-index-key
                key={`template${index}`}
                onClick={() => handleTemplateChange(index)}
                type="button"
              >
                <img
                  alt=""
                  className={cx('template-illustration', {
                    'illustration-background': !template.illustration,
                  })}
                  src={template.backgroundImage || template.illustration}
                />
              </button>
            ))}
          </div>
        </Tabs.Tab>
        <Tabs.Tab label={t('theme-title')}>
          <div className={cx('themes', { column: isDisplayedInColumn })}>
            {THEMES.map((theme, index) => (
              <button
                className={cx('theme', {
                  selected: selectedThemeIndex === index,
                })}
                // eslint-disable-next-line react/no-array-index-key
                key={`theme${index}`}
                onClick={() => handleThemeChange(index)}
                type="button"
              >
                <div>
                  <div className={cx('theme-colors')}>
                    <div
                      className={cx('theme-color', 'theme-index-4')}
                      style={{ background: theme.titleProps.fill }}
                    />
                    <div
                      className={cx('theme-color', 'theme-index-3')}
                      style={{
                        background: theme.subtitleProps.fill,
                      }}
                    />
                    <div
                      className={cx('theme-color', 'theme-index-2')}
                      style={{ background: theme.textProps.fill }}
                    />
                    <div
                      className={cx('theme-color', 'theme-index-1')}
                      style={{ background: theme.backgroundColor }}
                    />
                  </div>
                  <p
                    className={cx('theme-text')}
                    style={{
                      fontFamily: theme.titleProps.fontFamily,
                      fontWeight: theme.titleProps.fontWeight,
                      fontSize: theme.titleProps.fontSize / 2,
                    }}
                  >
                    {theme.titleProps.fontName}
                  </p>
                  <p
                    className={cx('theme-text')}
                    style={{
                      fontFamily: theme.subtitleProps.fontFamily,
                      fontWeight: theme.subtitleProps.fontWeight,
                      fontSize: theme.subtitleProps.fontSize / 2,
                    }}
                  >
                    {theme.subtitleProps.fontName}
                  </p>
                  <p
                    className={cx('theme-text')}
                    style={{
                      fontFamily: theme.textProps.fontFamily,
                      fontWeight: theme.textProps.fontWeight,
                      fontSize: theme.textProps.fontSize / 2,
                    }}
                  >
                    {theme.textProps.fontName}
                  </p>
                </div>
              </button>
            ))}
          </div>
        </Tabs.Tab>
      </Tabs>
    </div>
  );
};

DesignToolbar.propTypes = {
  className: PropTypes.string,
  fabricRef: PropTypes.object,
  isDisplayedInColumn: PropTypes.bool.isRequired,
};

export default DesignToolbar;
