import THEMES from '../data/themes';

const changeTheme = (fabricRef, index) => {
  fabricRef.current.getObjects().forEach((object) => {
    if (object.get('fontWeight') === 900) {
      object.selectAll();
      object.setSelectionStyles(THEMES[index].titleProps);
      object.set(THEMES[index].titleProps);
    }

    if (object.get('fontWeight') === 700) {
      object.selectAll();
      object.setSelectionStyles(THEMES[index].subtitleProps);
      object.set(THEMES[index].subtitleProps);
    }

    if (object.get('fontWeight') === 400) {
      object.selectAll();
      object.setSelectionStyles(THEMES[index].textProps);
      object.set(THEMES[index].textProps);
    }

    if (object.get('type') === 'group') {
      // eslint-disable-next-line no-underscore-dangle
      object._objects.forEach((groupObject) => {
        if (groupObject.get('type') === 'circle') {
          groupObject.set('fill', THEMES[index].bulletProps.background);
        }

        if (groupObject.get('fontWeight')) {
          groupObject.selectAll();
          groupObject.setSelectionStyles(THEMES[index].bulletProps);
          groupObject.set(THEMES[index].bulletProps);
        }
      });
    }
  });
  fabricRef.current.setBackgroundColor(THEMES[index].backgroundColor);
};

export default changeTheme;
