import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { LaTeX2HTML, ASCIIMath2HTML } from 'helpers/text';

import CleanedText from './CleanedText';

const domparser = new DOMParser();

const CleanedTextContainer = ({ children, ...props }) => {
  const html = useMemo(() => {
    const dirtyWithSpaces = children
      .replace(/></g, '> <')
      .replace(/<span class="fr-video(.*?)<\/span>/g, '');
    const clean =
      domparser.parseFromString(dirtyWithSpaces, 'text/html').body
        .textContent || '';
    const purified = DOMPurify.sanitize(clean);
    return purified
      .replace(/\$(.*?)\$/g, LaTeX2HTML)
      .replace(/`(.*?)`/g, ASCIIMath2HTML)
      .replace(/<math>(.*?)<\/math>/g, ASCIIMath2HTML)
      .replace(/&lt;math&gt;(.*?)&lt;\/math&gt;/g, ASCIIMath2HTML);
  }, [children]);
  return <CleanedText html={html} {...props} />;
};

CleanedTextContainer.propTypes = {
  children: PropTypes.string,
};

CleanedTextContainer.defaultProps = {
  children: '',
};

export default CleanedTextContainer;
