import { fabric } from 'fabric';
import storeJS from 'store';

import TEMPLATES from '../data/templates';
import changeTheme from './changeTheme';
import loadBackground from './loadBackground';

const TR_KEY = 'Questions/Shared/SlideBuilder/TextToolbar';

function getOldElement(oldSlide, templateTextBox, t) {
  return oldSlide.find((oldObject, index) => {
    const isSameTypeOfText =
      oldObject.fontWeight === templateTextBox.fontWeight;

    const isOldObjectAGroup = oldObject.type === 'group';

    const isOldObjectDefaultTitle = oldObject.text === t(`${TR_KEY}/title`);

    const isOldObjectDefaultSubtitle =
      oldObject.text === t(`${TR_KEY}/subtitle`);

    const isOldObjectDefaultText = oldObject.text?.includes('Lorem ipsum');

    const shouldReplaceByOldElement =
      isSameTypeOfText &&
      !isOldObjectAGroup &&
      !isOldObjectDefaultTitle &&
      !isOldObjectDefaultSubtitle &&
      !isOldObjectDefaultText;

    // If we use the old element to replace the text box, we remove it from the old slide for the next iteration
    if (shouldReplaceByOldElement) {
      oldSlide.splice(index, 1);
    }

    return shouldReplaceByOldElement;
  });
}

function getTextBoxes(template, oldSlide, t) {
  return template.textBoxes?.map((templateTextBox) => {
    const oldElement = getOldElement(oldSlide, templateTextBox, t);

    // If there is an old element existing with the same role as the template text box, replace the template text box with the old element
    // and place it in the same position as the template text box
    if (oldElement) {
      const newLeftPosition =
        templateTextBox.left +
        (templateTextBox.width / 2 - oldElement.width / 2);

      const newTopPosition =
        templateTextBox.top +
        (templateTextBox.height / 2 - oldElement.height / 2);

      return {
        ...templateTextBox,
        text: oldElement.text,
        width: oldElement.width,
        height: oldElement.height,
        left: newLeftPosition,
        top: newTopPosition,
      };
    }

    // If no olf element exists, return the template text box
    return {
      ...templateTextBox,
      // without this step translation keys are
      // not translated during loadFromJSON function
      text: t(templateTextBox.text),
    };
  });
}

// Keep only the elements that are not default elements
function getRemainingOldSlideElements(oldSlide, t) {
  return oldSlide.filter((oldObject) => {
    const isOldObjectAGroup = oldObject.type === 'group';

    const hasOldObjectOnlyPaths = oldObject.objects?.every(
      (subObject) => subObject.type === 'path',
    );

    const isOldObjectDefaultTitle = oldObject.text === t(`${TR_KEY}/title`);

    const isOldObjectDefaultSubtitle =
      oldObject.text === t(`${TR_KEY}/subtitle`);

    const isOldObjectDefaultText = oldObject.text?.includes('Lorem ipsum');

    const hasDefaultImage = oldObject.src?.includes('imagePlaceholder');

    return (
      (!isOldObjectAGroup || hasOldObjectOnlyPaths) &&
      !isOldObjectDefaultTitle &&
      !isOldObjectDefaultSubtitle &&
      !isOldObjectDefaultText &&
      !hasDefaultImage
    );
  });
}

const changeTemplate = ({ currentSlide, fabricRef, t, templateIndex }) => {
  const oldSlide = [...currentSlide];

  const selectedTemplate = TEMPLATES[templateIndex];

  const textBoxes = getTextBoxes(selectedTemplate, oldSlide, t);

  const remainingOldSlideElements = getRemainingOldSlideElements(oldSlide, t);

  const remainingOldObjects = remainingOldSlideElements?.filter(
    (o) => o.type !== 'i-text',
  );

  const remainingOldTextBoxes = remainingOldSlideElements?.filter(
    (o) => o.type === 'i-text',
  );

  const newObjects = selectedTemplate.objects
    ? [...selectedTemplate.objects, ...remainingOldObjects]
    : remainingOldObjects;

  const newTextBoxes =
    textBoxes && remainingOldTextBoxes
      ? [...textBoxes, ...remainingOldTextBoxes]
      : textBoxes || remainingOldTextBoxes;

  const template = {
    ...selectedTemplate,
    objects: newObjects,
    textBoxes: newTextBoxes,
  };

  // Load the template in the slide
  fabricRef.current.loadFromJSON(template, () => {
    // Apply template background image
    if (template.backgroundImage) {
      loadBackground(fabricRef, template);
    }

    // Apply slide background color
    if (storeJS.get('slide')?.backgroundColor) {
      fabricRef.current.setBackgroundColor(
        storeJS.get('slide')?.backgroundColor,
      );
    } else if (!storeJS.get('slide')?.themeIndex) {
      fabricRef.current.setBackgroundColor(selectedTemplate.backgroundColor);
    }

    // Apply texts
    template.textBoxes?.map((object) => {
      const text = new fabric.Textbox(object.text, {
        ...object,
        // if we don't add it,
        // design changes on one subtitle element
        // are applied to every subtitle in the canvas
        styles: [],
      });
      fabricRef.current.add(text);
      return null;
    });

    // Apply last theme used on the template content
    if (storeJS.get('slide')?.themeIndex) {
      const index = storeJS.get('slide').themeIndex;
      changeTheme(fabricRef, index);
    }

    fabricRef.current.renderAll();
  });
};

export default changeTemplate;
