import { fabric } from 'fabric';

import RotateIcon from '../images/rotate.svg';
import TrashIcon from '../images/trash.svg';

function deleteObject(eventData, transform) {
  const { target } = transform;
  const { canvas } = target;
  canvas.remove(target);
  canvas.requestRenderAll();
}

function renderIcon(ctx, left, top, fabricObject, icon) {
  const size = 50;

  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(icon, -size / 2, -size / 2, size, size);
  ctx.restore();
}

function renderDeleteIcon(ctx, left, top, styleOverride, fabricObject) {
  const deleteIcon = document.createElement('img');
  deleteIcon.src = TrashIcon;

  renderIcon(ctx, left, top, fabricObject, deleteIcon);
}

function renderRotateIcon(ctx, left, top, styleOverride, fabricObject) {
  const rotateIcon = document.createElement('img');
  rotateIcon.src = RotateIcon;

  renderIcon(ctx, left, top, fabricObject, rotateIcon);
}

// Controls properties
fabric.Object.prototype.transparentCorners = false;
fabric.Object.prototype.cornerColor = '#BFF6EC';
fabric.Object.prototype.cornerStyle = 'circle';
fabric.Object.prototype.cornerSize = 12;

const deleteControlProperties = {
  x: 0.5,
  y: 0,
  offsetX: 40,
  offsetY: 0,
  cursorStyle: 'pointer',
  mouseUpHandler: deleteObject,
  render: renderDeleteIcon,
  cornerSize: 24,
};

fabric.Object.prototype.controls.deleteControl = new fabric.Control(
  deleteControlProperties,
);
fabric.Textbox.prototype.controls.deleteControl = new fabric.Control(
  deleteControlProperties,
);

const rotationControlProperties = {
  x: 0,
  y: -0.5,
  offsetX: 0,
  offsetY: -40,
  cursorStyle: 'grab',
  actionHandler: fabric.controlsUtils.rotationWithSnapping,
  actionName: 'rotate',
  render: renderRotateIcon,
  cornerSize: 38,
};

fabric.Object.prototype.controls.mtr = new fabric.Control(
  rotationControlProperties,
);
fabric.Textbox.prototype.controls.mtr = new fabric.Control(
  rotationControlProperties,
);
