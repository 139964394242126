import classNames from 'classnames/bind';
import { fabric } from 'fabric';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'uikit/Button';
import ImagePicker from 'uikit/ImagePicker';

import useModal from '../../../../hooks/useModal';
import { saveHistory } from '../config/history';
import styles from './MediaToolbar.module.scss';

const cx = classNames.bind(styles);

const MediaToolbar = ({
  className = null,
  fabricRef = null,
  isDisplayedInColumn,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Shared/SlideBuilder/MediaToolbar',
  });

  const { isModalOpen, handleModalClose, handleModalOpen } = useModal(false);

  function handleImageAdd(imageUrl) {
    saveHistory(fabricRef);
    fabric.Image.fromURL(imageUrl, (img) => {
      img.set({ snapAngle: 15 });
      fabricRef.current.add(img).centerObject(img);
    });
    handleModalClose();
  }

  return (
    <div
      className={cx('container', className, { column: isDisplayedInColumn })}
    >
      <p className={cx('title')}>{t('media-title')}</p>
      <div className={cx('image-actions')}>
        <Button className={cx('action')} onClick={handleModalOpen}>
          {t('image-add')}
        </Button>
      </div>
      <ImagePicker
        handleModalClose={handleModalClose}
        isModalOpen={isModalOpen}
        onSelect={handleImageAdd}
      />
    </div>
  );
};

MediaToolbar.propTypes = {
  className: PropTypes.string,
  fabricRef: PropTypes.object,
  isDisplayedInColumn: PropTypes.bool.isRequired,
};

export default MediaToolbar;
