import classNames from 'classnames/bind';
import { fabric } from 'fabric';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import storeJS from 'store';

import useModal from 'hooks/useModal';
import Button from 'uikit/Button';
import ImagePicker from 'uikit/ImagePicker';
import Menu from 'uikit/Menu';

import { saveHistory } from '../config/history';
import ALIGNMENT_POSITIONS from '../data/alignements';
import COLORS from '../data/colors';
import DEFAULT_CANVAS_PROPERTIES from '../data/defaultCanvasProperties';
import FONTS from '../data/fonts';
import FONT_SIZES from '../data/fontSizes';
import resizeImage from '../helpers/resizeImage';
import styles from './SecondaryToolbar.module.scss';

const cx = classNames.bind(styles);

const SecondaryToolbar = ({
  className = null,
  fabricRef = null,
  selectedObject = null,
}) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Shared/SlideBuilder/SecondaryToolbar',
  });

  const { isModalOpen, handleModalClose, handleModalOpen } = useModal(false);

  const [textColor, setTextColor] = useState('black');

  // set the color of the text to display it in the toolbar
  useEffect(() => {
    if (['i-text', 'group'].includes(selectedObject?.type)) {
      const colorName = getColorName(getColor(selectedObject)) || 'black';

      setTextColor(colorName);
    } else {
      setTextColor('black');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedObject]);

  function getColor(selectedObject) {
    const isObjectGroupOrSVG = selectedObject.type === 'group';

    const isTextSelected = selectedObject.getSelectionStyles?.()[0]?.fill;

    const isColorAppliedOnCharacters = selectedObject.styles?.[0]?.[0]?.fill;

    if (isObjectGroupOrSVG) {
      return getFirstCharacterColor(selectedObject);
    } else if (isTextSelected) {
      return getFirstSelectedCharacterColor(selectedObject);
    } else if (isColorAppliedOnCharacters) {
      return getFirstStyleColor(selectedObject);
    } else {
      return getGlobalColor(selectedObject);
    }
  }

  function getColorHex(name) {
    return COLORS.find((color) => color.name === name)?.hex;
  }

  function getColorName(hex) {
    return COLORS.find((color) => color.hex === hex)?.name;
  }

  function getFirstCharacterColor(selectedObject) {
    return selectedObject._objects.filter(
      (object) => object.type !== 'circle',
    )[0].fill;
  }

  function getFirstSelectedCharacterColor(selectedObject) {
    return selectedObject.getSelectionStyles()[0].fill;
  }

  function getFirstStyleColor(selectedObject) {
    return selectedObject.styles[0][0].fill;
  }

  function getGlobalColor(selectedObject) {
    return selectedObject.fill;
  }

  function handleBackgroundChange(imageUrl) {
    saveHistory(fabricRef);

    fabric.Image.fromURL(imageUrl, (img) => {
      resizeImage(
        img,
        DEFAULT_CANVAS_PROPERTIES.width,
        DEFAULT_CANVAS_PROPERTIES.height,
      );
      fabricRef.current.setBackgroundImage(
        img,
        fabricRef.current.renderAll.bind(fabricRef.current),
      );
      handleModalClose();
    });
  }

  function handleBackgroundColorChange(selectedColorName) {
    saveHistory(fabricRef);

    fabricRef.current.setBackgroundColor(getColorHex(selectedColorName), () => {
      fabricRef.current.renderAll();
      storeJS.set('slide', {
        ...storeJS.get('slide'),
        backgroundColor: getColorHex(selectedColorName),
      });
    });

    handleModalClose();
  }

  function handleBackgroundDelete() {
    saveHistory(fabricRef);
    handleBackgroundChange('');
  }

  function handleColorClick(background) {
    if (selectedObject?.type === 'i-text') {
      handleTextColorChange(background.name);
      // eslint-disable-next-line no-underscore-dangle
    } else if (selectedObject?._objects) {
      handleSVGColorChange(background.name);
    } else if (background.name === 'image-picker') {
      handleModalOpen();
    } else {
      handleBackgroundColorChange(background.name);
    }
  }

  function handleImageChange(imageUrl) {
    const selectedObjectWidth = selectedObject.width;
    const selectedObjectHeight = selectedObject.height;

    selectedObject.setSrc(imageUrl, (img) => {
      resizeImage(img, selectedObjectWidth, selectedObjectHeight);
      fabricRef.current.renderAll();
    });

    handleModalClose();
  }

  function handleSVGColorChange(selectedColorName) {
    saveHistory(fabricRef);

    selectedObject._objects.forEach((character) => {
      if (character.type !== 'circle') {
        character.set({
          fill: getColorHex(selectedColorName),
        });
      }
    });

    fabricRef.current.renderAll();
    setTextColor(selectedColorName);
  }

  function handleTextAlign(textAlign) {
    saveHistory(fabricRef);
    selectedObject.set('textAlign', textAlign);
    fabricRef.current.requestRenderAll();
  }

  function handleTextColorChange(selectedColor) {
    if (selectedObject.selectionStart !== selectedObject.selectionEnd) {
      saveHistory(fabricRef);
      selectedObject.setSelectionStyles({ fill: getColorHex(selectedColor) });
      saveGlobalProperties('fill', getColorHex(selectedColor));
      setTextColor(selectedColor);
      fabricRef.current.requestRenderAll();
    } else {
      selectedObject.selectAll();
      handleTextColorChange(selectedColor);
    }
  }

  function handleTextFontFamily(fontFamily) {
    if (selectedObject.selectionStart !== selectedObject.selectionEnd) {
      saveHistory(fabricRef);
      selectedObject.setSelectionStyles({ fontFamily });
      saveGlobalProperties('fontFamily', fontFamily);
      fabricRef.current.requestRenderAll();
    } else {
      selectedObject.selectAll();
      handleTextFontFamily(fontFamily);
    }
  }

  function handleTextFontSize(fontSize) {
    if (selectedObject.selectionStart !== selectedObject.selectionEnd) {
      saveHistory(fabricRef);
      selectedObject.setSelectionStyles({ fontSize });
      saveGlobalProperties('fontSize', fontSize);
      fabricRef.current.requestRenderAll();
    } else {
      selectedObject.selectAll();
      handleTextFontSize(fontSize);
    }
  }

  function handleTextStyle() {
    if (selectedObject.selectionStart !== selectedObject.selectionEnd) {
      saveHistory(fabricRef);

      const hasEveryLetterNoStyle = selectedObject
        .getSelectionStyles()
        .every((letter) => !letter.fontStyle);

      const isEveryLetterItalic = selectedObject
        .getSelectionStyles()
        .every((letter) => letter.fontStyle === 'italic');

      const isGlobalStyleItalic = selectedObject.fontStyle === 'italic';

      const fontStyle =
        isEveryLetterItalic || (hasEveryLetterNoStyle && isGlobalStyleItalic)
          ? 'normal'
          : 'italic';

      selectedObject.setSelectionStyles({ fontStyle });
      saveGlobalProperties('fontStyle', fontStyle);
      fabricRef.current.requestRenderAll();
    } else {
      selectedObject.selectAll();
      handleTextStyle();
    }
  }

  function handleTextWeight() {
    if (selectedObject.selectionStart !== selectedObject.selectionEnd) {
      saveHistory(fabricRef);

      const hasEveryLetterNoWeight = selectedObject
        .getSelectionStyles()
        .every((letter) => !letter.fontWeight);

      const isEveryLetterBold = selectedObject
        .getSelectionStyles()
        .every((letter) => letter.fontWeight === 900);

      const isGlobalStyleBold = selectedObject.fontWeight === 900;

      const fontWeight =
        isEveryLetterBold || (hasEveryLetterNoWeight && isGlobalStyleBold)
          ? 400
          : 900;

      selectedObject.setSelectionStyles({ fontWeight });
      saveGlobalProperties('fontWeight', fontWeight);
      fabricRef.current.requestRenderAll();
    } else {
      selectedObject.selectAll();
      handleTextWeight();
    }
  }

  function saveGlobalProperties(property, value) {
    const hasSelectionLastCharacter =
      selectedObject.selectionEnd === selectedObject.text.length;

    if (hasSelectionLastCharacter) {
      selectedObject.set(property, value);
    }
  }

  const currentBackground = getColorName(fabricRef?.current?.backgroundColor);

  return (
    <div className={cx('container', className)}>
      {(!selectedObject ||
        selectedObject.type === 'i-text' ||
        selectedObject.type === 'group') && (
        <Menu
          isHideOnClickDeactivated
          placement="right"
          toggle={
            <Button
              className={cx(
                'action',
                'background-button',
                `current-background-${
                  selectedObject ? textColor : currentBackground
                }`,
              )}
              icon={
                // eslint-disable-next-line no-underscore-dangle
                !selectedObject && fabricRef.current?.backgroundImage?._element
                  ? 'image'
                  : 'circle'
              }
              intent="white"
            />
          }
        >
          <p className={cx('color-title')}>
            {t(selectedObject ? 'text-color' : 'background')}
          </p>
          <div className={cx('colors')}>
            {COLORS.map((bg) =>
              selectedObject && bg.name === 'image-picker' ? null : (
                <Button
                  className={cx('color', bg.name, `background-${bg.name}`)}
                  icon={bg.name === 'image-picker' ? 'image' : '.'}
                  key={bg.name}
                  onClick={() => handleColorClick(bg)}
                />
              ),
            )}
          </div>
          {/* eslint-disable-next-line no-underscore-dangle */}
          {!selectedObject && fabricRef.current?.backgroundImage?._element && (
            <Button
              className={cx('delete-button')}
              onClick={handleBackgroundDelete}
            >
              {t('delete-background')}
            </Button>
          )}
        </Menu>
      )}

      {selectedObject?.type === 'i-text' && (
        <>
          <Menu
            isHideOnClickDeactivated
            placement="right"
            toggle={
              <Button
                className={cx('action', 'font-family-action')}
                icon="font"
                intent="white"
              />
            }
          >
            <div className={cx('font-family')}>
              {FONTS.map((fontFamily) => (
                <Button
                  className={cx('font-family-button')}
                  key={fontFamily}
                  onClick={() => handleTextFontFamily(fontFamily)}
                  style={{ fontFamily }}
                >
                  {fontFamily}
                </Button>
              ))}
            </div>
          </Menu>
          <Button
            className={cx('action')}
            icon="bold"
            intent="white"
            onClick={handleTextWeight}
          />
          <Button
            className={cx('action')}
            icon="italic"
            intent="white"
            onClick={handleTextStyle}
          />
          <Menu
            isHideOnClickDeactivated
            placement="right"
            toggle={
              <Button
                className={cx('action')}
                icon="align-left"
                intent="white"
              />
            }
          >
            <div className={cx('text-align')}>
              {ALIGNMENT_POSITIONS.map((alignmentPosition) => (
                <Button
                  className={cx('text-align-button')}
                  icon={`align-${alignmentPosition}`}
                  key={alignmentPosition}
                  onClick={() => handleTextAlign(alignmentPosition)}
                />
              ))}
            </div>
          </Menu>
          <Menu
            isHideOnClickDeactivated
            placement="right"
            toggle={
              <button
                className={cx('action', 'font-size-action')}
                type="button"
              >
                {`${selectedObject.fontSize}pt`}
              </button>
            }
          >
            <div className={cx('font-size')}>
              {FONT_SIZES.map((fontSize) => (
                <Button
                  className={cx('font-size-button')}
                  key={fontSize}
                  onClick={() => handleTextFontSize(fontSize)}
                >
                  {`${fontSize}pt`}
                </Button>
              ))}
            </div>
          </Menu>
        </>
      )}
      {selectedObject?.type === 'image' && (
        <Button icon="image" intent="white" onClick={handleModalOpen} />
      )}
      <ImagePicker
        current={
          selectedObject
            ? //  eslint-disable-next-line no-underscore-dangle
              selectedObject?._element?.currentSrc
            : fabricRef.background
        }
        handleModalClose={handleModalClose}
        isModalOpen={isModalOpen}
        onSelect={selectedObject ? handleImageChange : handleBackgroundChange}
      />
    </div>
  );
};

SecondaryToolbar.propTypes = {
  className: PropTypes.string,
  fabricRef: PropTypes.object,
  selectedObject: PropTypes.object,
};

export default SecondaryToolbar;
