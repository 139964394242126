const resizeImage = (img, containerWidth, containerHeight) => {
  const imgRatio = img.height / img.width;

  const containerRatio = containerHeight / containerWidth;

  const isContainerRatioAndImageRatioEqual =
    imgRatio.toFixed(1) === containerRatio.toFixed(1);

  if (containerWidth / img.width < containerHeight / img.height) {
    const ratio = containerHeight / img.height;
    img.set('scaleY', img.scaleY * ratio);
    img.set('scaleX', img.scaleX * ratio);

    if (containerWidth < img.width && !isContainerRatioAndImageRatioEqual) {
      img.set('cropX', ((img.width - containerWidth) / 2) * ratio);
      img.set('width', containerWidth / ratio);
    }
  } else {
    const ratio = containerWidth / img.width;
    img.set('scaleY', img.scaleY * ratio);
    img.set('scaleX', img.scaleX * ratio);

    if (containerHeight < img.height && !isContainerRatioAndImageRatioEqual) {
      img.set('cropY', ((img.height - containerHeight) / 2) * ratio);
      img.set('height', containerHeight / ratio);
    }
  }
};

export default resizeImage;
