import classNames from 'classnames/bind';
import { fabric } from 'fabric';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryLocalApp, useQueryLocalEditor } from 'gql/queries/local';
import useHotkeys from 'hooks/useHotkeys';
import Icon from 'uikit/Icon';

import './config/controllers';
import { clearHistory, saveHistory, undo } from './config/history';
import {
  copy,
  deleteActiveObject,
  moveDown,
  moveLeft,
  moveRight,
  moveUp,
  paste,
} from './config/hotkeys';
import resizeCanvas from './config/resizeCanvas';
import snapToGrid from './config/snapToGrid';
import PROPERTIES_TO_SAVE from './data/propertiesToSave';
import DesignToolbar from './DesignToolbar/DesignToolbar';
import MediaToolbar from './MediaToolbar/MediaToolbar';
import TextToolbar from './TextToolbar/TextToolbar';
import SecondaryToolbar from './SecondaryToolbar/SecondaryToolbar';
import styles from './SlideBuilder.module.scss';

const cx = classNames.bind(styles);

const TOOLBARS = ['template', 'text', 'image'];

const SlideBuilder = ({ className = null }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'Questions/Slide2',
  });

  const {
    data: {
      app: { isMenuExpanded },
    },
  } = useQueryLocalApp();

  const {
    data: {
      editor: { questionEditing },
    },
  } = useQueryLocalEditor();

  const [historyStep, setHistoryStep] = useState();
  const [activeMenu, setActiveMenu] = useState('template');
  const [selectedObject, setSelectedObject] = useState();

  const canvasRef = useRef();
  const fabricRef = useRef();

  const [, , { setValue: setTitle }] = useField('title');
  const [, , { setValue: setSlideContent }] = useField('slideContent');

  useHotkeys('ctrl+c', () => copy(fabricRef));
  useHotkeys('cmd+c', () => copy(fabricRef));
  useHotkeys('ctrl+v', () => paste(fabricRef));
  useHotkeys('cmd+v', () => paste(fabricRef));
  useHotkeys('ctrl+z', () => undo(fabricRef));
  useHotkeys('cmd+z', () => undo(fabricRef));
  useHotkeys('backspace', () => deleteActiveObject(fabricRef));
  useHotkeys('delete', () => deleteActiveObject(fabricRef));
  useHotkeys('up', () => moveUp(fabricRef));
  useHotkeys('down', () => moveDown(fabricRef));
  useHotkeys('left', () => moveLeft(fabricRef));
  useHotkeys('right', () => moveRight(fabricRef));

  const updateQuestionContent = useCallback(() => {
    const slideContentToSave = JSON.stringify(
      fabricRef.current.toJSON(PROPERTIES_TO_SAVE),
    );
    const firstTitle = fabricRef.current._objects.find(
      (object) => object.fontWeight === 900,
    );
    const firstSubtitle = fabricRef.current._objects.find(
      (object) => object.fontWeight === 700,
    );
    const firstText = fabricRef.current._objects.find(
      (object) => object.fontWeight === 400,
    );

    function getTitle() {
      if (firstTitle) {
        return firstTitle.text;
      }
      if (firstSubtitle) {
        return firstSubtitle.text;
      }
      if (firstText) {
        return firstText.text;
      }
      return t('Questions/Slide2/name');
    }

    setSlideContent(slideContentToSave);

    setTitle(getTitle());
  }, [fabricRef, setSlideContent, setTitle, t]);

  // initiation of the canvas
  useEffect(() => {
    const initFabric = () => {
      fabricRef.current = new fabric.Canvas(canvasRef.current);
    };

    const disposeFabric = () => {
      fabricRef.current.dispose();
    };

    initFabric();

    // needed to render properly latex svg
    fabric.Object.NUM_FRACTION_DIGITS = 5;

    // Default background color
    fabricRef.current.setBackgroundColor('#f5f6fa');

    // load existing slide
    fabricRef.current.loadFromJSON(questionEditing.content.slideContent);

    resizeCanvas(fabricRef, isMenuExpanded);
    saveHistory(fabricRef);

    return () => {
      disposeFabric();
      clearHistory();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasRef, fabricRef, isMenuExpanded, questionEditing]);

  // Link actions to canvas events
  useEffect(() => {
    const slideContentToSave = JSON.stringify(
      fabricRef.current.toJSON(PROPERTIES_TO_SAVE),
    );

    fabricRef?.current?.on({
      'after:render': updateQuestionContent,
      'selection:created': selectObjects,
      'mouse:down': () => setHistoryStep(slideContentToSave),
      'selection:updated': selectObjects,
      'selection:cleared': deselectObjects,
      'object:moving': snapToGrid,
      'object:modified': () => saveHistory(fabricRef, historyStep),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fabricRef, historyStep, questionEditing]);

  // Resize canvas
  useEffect(() => {
    window.addEventListener('resize', () =>
      resizeCanvas(fabricRef, isMenuExpanded),
    );

    return () =>
      window.removeEventListener('resize', () =>
        resizeCanvas(fabricRef, isMenuExpanded),
      );
  }, [isMenuExpanded]);

  function deselectObjects() {
    setSelectedObject();
  }

  function getIcon(menu) {
    if (menu === 'template') {
      return 'grid-2';
    }
    if (menu === 'image') {
      return 'file-download';
    }
    return menu;
  }

  function handleToolbarChange(menu) {
    setActiveMenu(menu);
  }

  function selectObjects(event) {
    setSelectedObject(event.selected[0]);
    if (event.selected[0].type === 'i-text') {
      setActiveMenu('text');
    }
  }

  return (
    <div className={cx('container', className, { column: isMenuExpanded })}>
      <SecondaryToolbar fabricRef={fabricRef} selectedObject={selectedObject} />
      <div className={cx('canvas-container', { column: isMenuExpanded })}>
        <canvas className={cx('canvas')} ref={canvasRef} />
      </div>
      <div className={cx('toolbar')}>
        <div className={cx('toolbar-menu-container')}>
          <div className={cx('toolbar-menu')}>
            {TOOLBARS.map((menu) => (
              <button
                className={cx('toolbar-menu-button', {
                  isActive: activeMenu === menu,
                })}
                key={menu}
                onClick={() => handleToolbarChange(menu)}
                type="button"
              >
                <Icon className={cx('icon')} name={getIcon(menu)} />
              </button>
            ))}
          </div>
        </div>
        <div className={cx('toolbar-content')}>
          {activeMenu === 'template' ? (
            <DesignToolbar
              fabricRef={fabricRef}
              isDisplayedInColumn={isMenuExpanded}
            />
          ) : activeMenu === 'text' ? (
            <TextToolbar
              fabricRef={fabricRef}
              isDisplayedInColumn={isMenuExpanded}
            />
          ) : (
            <MediaToolbar
              fabricRef={fabricRef}
              isDisplayedInColumn={isMenuExpanded}
            />
          )}
        </div>
      </div>
    </div>
  );
};

SlideBuilder.propTypes = {
  className: PropTypes.string,
};

export default SlideBuilder;
