import DIRECTIONS from '../data/directions';
import { saveHistory } from './history';

let clipboard;

export const copy = (fabricRef) => {
  const activeObject = fabricRef.current.getActiveObject();
  if (activeObject) {
    activeObject.clone((cloned) => {
      clipboard = cloned;
    });
  }
};

export const deleteActiveObject = (fabricRef) => {
  fabricRef.current.remove(fabricRef.current.getActiveObject());
  fabricRef.current.requestRenderAll();
};

export const paste = (fabricRef) => {
  saveHistory(fabricRef);
  clipboard.clone((clonedObj) => {
    fabricRef.current.discardActiveObject();

    clonedObj.set({
      left: clonedObj.left + 10,
      top: clonedObj.top + 10,
      evented: true,
    });

    if (clonedObj.type === 'activeSelection') {
      clonedObj.set({ canvas: fabricRef.current });
      clonedObj.forEachObject((obj) => {
        fabricRef.current.add(obj);
      });
      clonedObj.setCoords();
    } else {
      fabricRef.current.add(clonedObj);
    }

    clipboard.top += 10;
    clipboard.left += 10;
    fabricRef.current.setActiveObject(clonedObj);
    fabricRef.current.requestRenderAll();
  });
};

function getMovement(activeObject, direction) {
  switch (direction) {
    case DIRECTIONS.UP:
      return { top: activeObject.top - 10 };
    case DIRECTIONS.DOWN:
      return { top: activeObject.top + 10 };
    case DIRECTIONS.LEFT:
      return { left: activeObject.left - 10 };
    case DIRECTIONS.RIGHT:
      return { left: activeObject.left + 10 };
    default:
      return {};
  }
}

function move(fabricRef, direction) {
  const activeObject = fabricRef.current.getActiveObject();
  const movement = getMovement(activeObject, direction);
  if (activeObject) {
    saveHistory(fabricRef);
    activeObject.set(movement);
    fabricRef.current.requestRenderAll();
  }
}

export const moveUp = (fabricRef) => {
  move(fabricRef, DIRECTIONS.UP);
};

export const moveDown = (fabricRef) => {
  move(fabricRef, DIRECTIONS.DOWN);
};

export const moveLeft = (fabricRef) => {
  move(fabricRef, DIRECTIONS.LEFT);
};

export const moveRight = (fabricRef) => {
  move(fabricRef, DIRECTIONS.RIGHT);
};
