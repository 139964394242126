import { HEIGHT, WIDTH } from '../data/constant';

const GRID_DIVISION = 4;

const snapToGrid = (options) => {
  const gridWidth = WIDTH / GRID_DIVISION;
  const gridHeight = HEIGHT / GRID_DIVISION;

  const elementHorizontalCenterPoint =
    options.target.left + options.target.width / 2;
  const elementVerticalCenterPoint =
    options.target.top + options.target.height / 2;

  const isElementCloseToHorizontalGridPoint =
    Math.round((elementHorizontalCenterPoint / gridWidth) * GRID_DIVISION) %
      GRID_DIVISION ===
    0;
  const isElementCloseToVerticalGridPoint =
    Math.round((elementVerticalCenterPoint / gridHeight) * GRID_DIVISION) %
      GRID_DIVISION ===
    0;

  const isElementCloseToGridPoint =
    isElementCloseToHorizontalGridPoint && isElementCloseToVerticalGridPoint;

  const newHorizontalPositionOnGrid =
    Math.round(elementHorizontalCenterPoint / gridWidth) * gridWidth -
    options.target.width / 2;
  const newVerticalPositionOnGrid =
    Math.round(elementVerticalCenterPoint / gridHeight) * gridHeight -
    options.target.height / 2;

  if (isElementCloseToGridPoint) {
    options.target
      .set({
        left: newHorizontalPositionOnGrid,
        top: newVerticalPositionOnGrid,
      })
      .setCoords();
  }
};

export default snapToGrid;
